import _slicedToArray from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import React from 'react';
import Image from 'next/future/image';
import { BREAKPOINTS, MAX_BREAKPOINTS } from '../../../themes/breakpoints';
import cn from 'classnames';
import styled from 'styled-components';
import styles from './Img.style';

/**
 * To simplify images, since Image component ended up being too complex, using !important, etc.
 */
var Img = function Img(_ref) {
  var className = _ref.className,
    _ref$classes = _ref.classes,
    classes = _ref$classes === void 0 ? {} : _ref$classes,
    src = _ref.src,
    mobileSrc = _ref.mobileSrc,
    sourcesSettings = _ref.sourcesSettings,
    alt = _ref.alt,
    fill = _ref.fill,
    sizes = _ref.sizes,
    style = _ref.style,
    width = _ref.width,
    height = _ref.height,
    priority = _ref.priority,
    blurDataURL = _ref.blurDataURL;
  // This could be refactored
  var renderSources = function renderSources() {
    var sources = [];
    sources.push(__jsx("source", {
      key: "max-xs",
      srcSet: "".concat(mobileSrc, "?fit=fill&fm=webp&h=").concat(sourcesSettings.xs['1x'].h, "&w=").concat(sourcesSettings.xs['1x'].w, ", ").concat(mobileSrc, "?fit=fill&fm=webp&h=").concat(sourcesSettings.xs['2x'].h, "&w=").concat(sourcesSettings.xs['2x'].w, "&q=75 2x"),
      type: "image/webp",
      media: "(max-width: ".concat(MAX_BREAKPOINTS.xs, ")")
    }));
    for (var _i = 0, _Object$entries = Object.entries(sourcesSettings); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
        key = _Object$entries$_i[0],
        value = _Object$entries$_i[1];
      var srcUrl = src;
      var quality = 75;
      if (mobileSrc && key === 'xs' || key === 'sm' || key === 'md') {
        srcUrl = mobileSrc;
        quality = 85;
      }
      sources.push(__jsx("source", {
        key: key,
        srcSet: "".concat(srcUrl, "?fit=fill&fm=webp&h=").concat(value['1x'].h, "&w=").concat(value['1x'].w, "&q=").concat(quality, ", ").concat(srcUrl, "?fit=fill&fm=webp&h=").concat(value['2x'].h, "&w=").concat(value['2x'].w, "&q=").concat(quality, " 2x"),
        type: "image/webp",
        media: "(min-width: ".concat(BREAKPOINTS[key], ")")
      }));
    }
    return sources;
  };
  return __jsx("div", {
    className: cn(classes.wrapper, 'nva-img', className)
  }, __jsx("picture", {
    className: cn(classes.picture, 'nva-img__picture')
  }, sourcesSettings && renderSources(), __jsx(Image, {
    className: cn(classes.img, 'nva-img__image'),
    alt: alt || '',
    src: src,
    fill: fill,
    sizes: fill ? sizes : null,
    unoptimized: sourcesSettings ? true : false,
    style: style,
    width: width,
    height: height,
    placeholder: blurDataURL ? 'blur' : 'empty',
    blurDataURL: blurDataURL,
    priority: priority,
    fetchpriority: priority ? 'high' : null
  })));
};
Img.defaultProps = {
  alt: '',
  priority: false,
  blurDataURL: null,
  sizes: null
};
export default styled(Img).withConfig({
  componentId: "sc-oaweig-0"
})(["", ";"], styles);