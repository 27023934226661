import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Twitter(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 15.4 12.8"
  }, props), __jsx("path", {
    d: "M9.7.1C8.3.6 7.4 1.9 7.5 3.4V4L7 3.9c-2-.3-3.9-1.2-5.3-2.6L.9.5l-.2.6c-.4 1.1-.1 2.4.7 3.3.5.5.4.5-.4.3-.2-.1-.3-.2-.5-.2 0 .5.2 1 .4 1.5.4.6.9 1.1 1.5 1.4l.5.3h-.6c-.6 0-.6 0-.6.2.4.9 1.2 1.6 2.1 1.8l.7.2-.6.4c-.9.7-1.9 1-3 1-.3 0-.6 0-.9.1.7.4 1.4.8 2.1 1 2.4.7 5.1.4 7.3-.8 1.6-1.1 2.9-2.6 3.7-4.4.4-1.1.7-2.2.8-3.4 0-.5 0-.6.7-1.2.3-.3.5-.6.8-.9.1-.2.1-.2-.5 0-1 .3-1.1.3-.6-.2.3-.3.6-.7.8-1.2 0 0-.2 0-.4.1-.3.2-.6.3-1 .4l-.6.2-.5-.4c-.3-.2-.6-.4-.9-.5-.7-.1-1.4-.1-2 0z"
  }));
}
export default Twitter;