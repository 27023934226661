import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Prev3(props) {
  return __jsx("svg", _extends({
    width: 14.143,
    height: 14.142,
    viewBox: "0 0 14.143 14.142"
  }, props), __jsx("path", {
    d: "M8.371 12.844L.001 7.072 8.371 1.3z",
    fill: "#fff"
  }));
}
export default Prev3;