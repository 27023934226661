import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function Next3(props) {
  return __jsx("svg", _extends({
    width: 14.143,
    height: 14.142,
    viewBox: "0 0 14.143 14.142"
  }, props), __jsx("path", {
    d: "M5.772 1.298l8.37 5.773-8.37 5.772z",
    fill: "#fff"
  }));
}
export default Next3;